<style lang="scss">
.add-target-modal{
  &__roles{
    max-height: 200px;
    overflow-y: auto;
  }
}
</style>

<template>
  <div class="add-group-modal">
    <section class="mb-2">
      <b-form-input v-model="group.name"
                    required
                    class="mb-1" placeholder="Group Name*"
      />
      <b-form-input v-model="group.description"
                    required
                    class="mb-1" placeholder="Group Description*"
      />
      <v-select
        v-model="selected"
        multiple
        label="title"
        :options="options"
      />
    </section>
    <section class="d-inline-flex full-width mt-2">
      <div class="ui-spacer" />
      <b-button class="mr-2" @click="$emit('close', true)">Cancel</b-button>
      <b-button :class="{'disabled': !checkFormValid()}"
                variant="primary" @click="updateGroup()"
      >Update Group</b-button>
    </section>

  </div>
</template>

<script>
import NotificationGroupsService from '@/services/NotificationGroupsService'
import vSelect from 'vue-select'
import UserService from '@/services/UserService'

  export default {
    components: {
      vSelect,
    },
    props: {
      uid: {
        required: true,
        type: String,
      },
      groupExisting: {
        required: true,
        type: Object,
      },
    },
    data() {
      return {
        group: {
          name: '',
          description: '',
          users: [],
        },
        users: [],
        options: [],
        selected: [],
      };
    },
    mounted() {
      this.getUsers()
      this.getGroup();
    },
    methods: {

      getGroup() {
          this.loading = true;
        NotificationGroupsService.getGroup(this.uid).then(res => {
          this.group = res.data;
          const selectArr = [];
          this.group.users.forEach(value => {
            selectArr.push({ title: value.name, uid: value.uid });
          });
          this.selected = selectArr
          }).catch(() => {
            this.$toast.error('Could not get group, please refresh and try again', {
              toastClassName: ['toast-std', 'warning-toast'],
            });
          }).finally(() => {
            this.loading = false;
          });
      },
      setGroupFromExisting() {
        const {
          name, description, users,
        } = this.groupExisting;
        const groupGroup = {
          name, description, users,
        };
        this.group = groupGroup;
      },
      updateGroup() {
        const users = [];
        this.selected.forEach(value => {
          users.push(value.uid);
        });
        this.group.users = users
        NotificationGroupsService.updateGroup(this.group.uid, this.group).then(() => {
          this.$toast.success(`Updated group ${this.group.name} Successfully`, {
            toastClassName: ['toast-std', 'success-toast'],
          });

          this.$emit('refresh', true);
        }).catch(() => {
          this.$toast.error('Could not update group, please refresh and try again', {
            toastClassName: ['toast-std', 'warning-toast'],
          });
        });
      },
      getUsers() {
        UserService.getUsers().then(res => {
          this.users = res.data.data;
          const arr = [];
          this.users.forEach(value => {
            arr.push({ title: value.name, uid: value.uid });
          });
          this.options = arr
        }).catch(() => {
          this.$toast.error('Could not get users, please refresh and try again', {
            toastClassName: ['toast-std', 'warning-toast'],
          });
        });
      },
      checkFormValid() {
        const name = this.group.name.length > 0;
        const description = this.group.description.length > 0;
        const users = this.group.users.length > 0;

        return name && description && users;
      },
    },
  };
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-select.scss';
</style>
