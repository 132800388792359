<style lang="scss">
.add-target-modal{
  &__roles{
    max-height: 200px;
    overflow-y: auto;
  }
}
</style>

<template>
  <div class="add-group-modal">
    <section class="mb-2">
      <b-form-input v-model="group.name"
                    required
                    class="mb-1" placeholder="Group Name*"
      />
      <b-form-input v-model="group.description"
                    required
                    class="mb-1" placeholder="Group Description*"
      />
      <v-select
        v-model="group.users"
        transition=""
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        multiple
        placeholder="Group Members"
        label="title"
        :options="options"
      />
    </section>
    <section class="d-inline-flex full-width mt-2">
      <div class="ui-spacer" />
      <b-button class="mr-2" @click="$emit('close', true)">Cancel</b-button>
      <b-button :class="{'disabled': !checkFormValid()}"
                variant="primary" @click="addGroup()"
      >Add new Group</b-button>
    </section>

  </div>
</template>

<script>
import NotificationGroupsService from '@/services/NotificationGroupsService'
import vSelect from 'vue-select'
import UserService from '@/services/UserService'

  export default {
    components: {
      vSelect,
    },
    props: {},
    data() {
      return {
        group: {
          name: '',
          description: '',
          users: [],
        },
        users: [],
        options: [],
      };
    },
    mounted() {
      this.getUsers()
    },
    methods: {
      addGroup() {
        const users = [];
        this.group.users.forEach(value => {
          users.push(value.uid);
        });
        this.group.users = users
        NotificationGroupsService.createGroup(this.group).then(() => {
          this.$toast.success(`Added group ${this.group.name} Successfully`, {
            toastClassName: ['toast-std', 'success-toast'],
          });

          this.$emit('refresh', true);
        }).catch(() => {
          this.$toast.error('Could not add group, please refresh and try again', {
            toastClassName: ['toast-std', 'warning-toast'],
          });
        });
      },
      getUsers() {
        UserService.getUsers().then(res => {
          this.users = res.data.data;
          const arr = [];
          this.users.forEach(value => {
            arr.push({ title: value.name, uid: value.uid });
          });
          this.options = arr
        }).catch(() => {
          this.$toast.error('Could not get users, please refresh and try again', {
            toastClassName: ['toast-std', 'warning-toast'],
          });
        });
      },
      checkFormValid() {
        const name = this.group.name.length > 0;
        const description = this.group.description.length > 0;
        const users = this.group.users.length > 0;

        return name && description && users;
      },
    },
  };
</script>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-select.scss';
</style>
