import axios from '@axios';
import HelperService from '@/services/HelperService'

const baseUrl = '/notification_groups';

export default {
  getGroups(filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}${queryString}`);
  },
  createGroup(group) {
    return axios.post(`${baseUrl}`, group);
  },
  getGroup(uid) {
    return axios.get(`${baseUrl}/${uid}`);
  },
  deleteGroup(uid) {
    return axios.delete(`${baseUrl}/${uid}`);
  },
  updateGroup(uid, targetDto) {
    return axios.put(`${baseUrl}/${uid}`, targetDto);
  },
};
