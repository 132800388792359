export default {
  /**
   * Build a query string from a filters object using key value pairs
   * */
  buildSearchQueryString(filters) {
    let query = '?';

    // eslint-disable-next-line no-restricted-syntax
    for (const key in filters) {
      if (filters[key]) {
        query += `${key}=${filters[key]}&`;
      }
    }

    query = query.substr(0, query.length - 1); // remove any extra '&' symbols

    return query;
  },
};
