<style lang="scss">

</style>
<!--eslint-disable-->
<template>
  <div class="issues-page">

    <b-card no-body class="mb-0">
      <div class="card-header">
        <h4 class="card-title">Notification Groups</h4>
        <section class="d-flex align-items-center justify-content-end">
          <b-button v-if="$can('Create', 'NotificationGroup')" @click="$bvModal.show('modal-add-group')" class="btn-success pull-right">Add Group</b-button>
        </section>
      </div>
      <section>
        <b-table
          class="data-table"
          hover
          :items="groups"
          :fields="headers"
          :current-page="currentPage"
          :per-page="0"
        >
          <template #cell(actions)="data">
            <b-dropdown class="m-0"  variant="link" right toggle-class="text-decoration-none" no-caret>
              <template #button-content>
                <i class="fas fa-ellipsis-v"></i>
              </template>
              <b-dropdown-item-button class="full-nested-btn" v-if="$can('Update', 'NotificationGroup')"
                                      @click="showGroupModal(data.item, 'modal-update-group')">
                Update Group
              </b-dropdown-item-button>
              <b-dropdown-item-button class="full-nested-btn" v-if="$can('Delete', 'NotificationGroup')"
                                      @click="showGroupModal(data.item, 'modal-delete-group')">
                Delete Group
              </b-dropdown-item-button>
            </b-dropdown>
          </template>
        </b-table>
        <b-row>
          <b-col md="6" class="my-1" v-if="total_count > filters.page_size">
            <b-pagination @change="getMoreIssues" :total-rows="total_count" :@refresh="refreshGroups()"  @close="closeModals()" per-page="filters.page_size" v-model="currentPage" class="my-0"/>
          </b-col>
          <b-col class="ml-2 mt-1" v-if="total_count === 0">
            <p >No results</p>
          </b-col>
        </b-row>
      </section>
    </b-card>
    <b-modal id="modal-add-group" title="Add new group" hide-footer>
      <add-group @refresh="refreshGroups()" @close="closeModals()"></add-group>
    </b-modal>

    <b-modal id="modal-update-group" title="Update group" hide-footer>
      <update-group v-if="selectedGroup" :group-existing="selectedGroup" :uid="selectedGroup.uid" @refresh="refreshGroups()" @close="closeModals()"></update-group>
    </b-modal>
    <b-modal id="modal-delete-group" title="Delete group" hide-footer>
      <delete-modal
        v-if="selectedGroup"
        @close="closeModals()" @delete="deleteGroup(selectedGroup)"
        :subtitle="selectedGroup.name"
        title="Are you sure you wish to delete group"></delete-modal>
    </b-modal>
  </div>
</template>
<!--eslint-enable-->

<script>
import { BPagination } from 'bootstrap-vue'
import NotificationGroupsService from '@/services/NotificationGroupsService'
import DeleteModal from '../../components/modals/DeleteModal.vue';
import AddGroup from './sections/AddGroup.vue';
import UpdateGroup from './sections/UpdateGroup.vue';

export default {
  name: 'Issues',
  components: {
    BPagination,
    AddGroup,
    DeleteModal,
    UpdateGroup,
  },
  props: {},
  data() {
    return {
      selectedGroup: null,
      headers: [
        {
          key: 'name',
          label: 'Name',
        },
        {
          key: 'description',
          label: 'Description',
        },
        {
          key: 'actions',
          label: 'Actions',
          class: 'text-right',
        },
      ],
      groups: [],
      currentPage: 1,
      filters: {
        page: 0,
        page_size: 25,
        search: '',
      },
      total_count: 0,
    };
  },
  mounted() {
    this.getGroups();
  },
  methods: {
    refreshGroups() {
      this.closeModals();
      this.getGroups();
    },
    closeModals() {
      this.$bvModal.hide('modal-add-group');
      this.$bvModal.hide('modal-update-group');
      this.$bvModal.hide('modal-delete-group');
    },
    getMoreGroups(val) {
      this.filters.page = val - 1; // api index starts at 0, bootstrap starts at 1 lol
      this.getGroups();
    },
    showGroupModal(group, modalName) {
      this.setSelectedGroup(group);
      this.$bvModal.show(modalName);
    },
    setSelectedGroup(group) {
      this.selectedGroup = group;
    },
    getGroups() {
      NotificationGroupsService.getGroups(this.filters).then(res => {
        this.groups = res.data.data;
        this.groups.page = res.data.meta.page;
        this.groups.page_size = res.data.meta.page_size;
        this.total_count = res.data.meta.total_count;
      }).catch(() => {
        this.$toast.error('Could not get groups, please refresh and try again', {
          toastClassName: ['toast-std', 'warning-toast'],
        });
      });
    },
    deleteGroup(group) {
      NotificationGroupsService.deleteGroup(group.uid).then(() => {
        this.$toast.success(`Deleted group ${group.name} Successfully`, {
          toastClassName: ['toast-std', 'success-toast'],
        });
        this.closeModals();
        this.refreshGroups();
      }).catch(() => {
        this.$toast.error('Could not delete groups, please refresh and try again', {
          toastClassName: ['toast-std', 'warning-toast'],
        });
      });
    },
    clearFilters() {
      this.filters = {
        page: 0,
        page_size: 25,
        search: '',
      };

      this.getMoreGroups(1);
    },
  },
};
</script>
